import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import { activityLogSelector, saveValue, sendBankStatementsLink, userDetailsSelector } from "../../store/slices/applicationFormSlice";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE, creditCardModalInfo, payInvoiceModalInfo } from "../../constants";
import { userPermissionsSelector, userSelector } from "../../store/slices/userSlice";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import { HtmlTooltip } from "../ProductSelector/Product";
import { customerSelector } from "../../store/slices/customerSlice";

const CustomerFinancialStatement = React.memo(({ customer, applicationId }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userSelector);
  const permissions = useSelector(userPermissionsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);
  const isDisable = isCustomerLogin ? true : permissions?.application?.edit;
  const { enqueueSnackbar } = useSnackbar();
  const activityLog = useSelector(activityLogSelector);
  const { bankStatementsRequest } = useSelector(userDetailsSelector);
  const bankStatementsLoading = "";
  const [bankStatementDays, setBankStatementDays] = useState("90");

  const isCustomerEmail = customer?.emails?.[0]?.address
  const isCustomerPhoneNumber = customer?.phoneNumbers?.[0]?.number

  const isBankStatementDisabled = !isCustomerPhoneNumber
    || !isCustomerEmail
    // || !userDetails?.is_invoice_pending
    || !userDetails?.is_service_enable
    || !bankStatementDays

  const filterActivityLog = (criteria) => activityLog?.filter(criteria);

  const isCustomerMatch = (item) => item?.customer === customer._id;

  const isSendBankStatementsLink = (item) =>
    item?.name === "SendBankStatementsLink" && isCustomerMatch(item);

  const isBankStatementInsert = (item) =>
    item?.activityType === "BankStatement" && item?.name === "Insert" && isCustomerMatch(item);

  const bankStatementLinkSentList = filterActivityLog(isSendBankStatementsLink);
  const bankStatementCompletedList = filterActivityLog(isBankStatementInsert);

  const isBankStatementLinkSent = activityLog?.reduce((latest, item) => isSendBankStatementsLink(item) ? item : latest, null);

  const isResendLink =
    isBankStatementLinkSent &&
    customer?.bankStatements &&
    bankStatementLinkSentList?.length >= 2;

  const isResendBankStatementCompleted =
    isResendLink &&
    customer?.bankStatements &&
    bankStatementCompletedList?.length === bankStatementLinkSentList?.length;

  const isBankStatementCompleted =
    isBankStatementLinkSent &&
    customer?.bankStatements &&
    (isResendLink ? isResendBankStatementCompleted : true);

  const bankStatementLink =
    !isBankStatementCompleted && isBankStatementLinkSent
      ? isBankStatementLinkSent?.values?.url
      : '';

  const handle = {
    setStatementsDays: (day) => {
      setBankStatementDays(day);
    },
    handleSendBankStatementsLink: (customerId, applicationId, mobile, bankStatementDays) => {
      dispatch(saveValue({ bankStatementsLoading: true }));
      dispatch(
        sendBankStatementsLink({
          data: {
            customerId,
            applicationId,
            bankStatementDays,
            mobile,
          },
        }),
      ).then((res) => {
        if (!res?.payload?.response_error) {
          enqueueSnackbar(SUCCESS_MESSAGE.bankStatements, {
            variant: "success",
            autoHideDuration: 5000,
          });

        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    getBankStatementLink(input) {
      if (typeof input === 'string') {
        return input
      } else if (typeof input === 'object' && input !== null) {
        return input?.shortUrl || ""
      }
      return ''
    }
  };

  return (
    <React.Fragment>
      <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
        Bank Statements
      </Typography>

      {customer?.bankStatements && (
        <>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Bank accounts summary (current balance)
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }} spacing={2}>
            {customer?.bankStatements &&
              customer?.bankStatements?.bankAccounts?.map((bank, i) => (
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{bank.bankName}</Typography>
                  {bank.bankAccounts.map((account) => (
                    <Typography variant="body2">
                      <strong>{account.accountName}</strong>: {account.currentBalance}
                    </Typography>
                  ))}
                </Grid>
              ))}
          </Grid>
        </>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Credits
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i < 3)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Responsible lending flags
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 2 && i < 7)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Liabilities
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 6 && i < 10)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      {customer?.bankStatements && (
        <React.Fragment>
          <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: "5px" }}>
            Debits
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }}>
            {customer?.bankStatements &&
              customer?.bankStatements?.decisionMetrics?.map((metric, i) => {
                if (i > 9 && metric.value * 1 !== 0)
                  return (
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{metric.name}</Typography>
                      <Typography variant="body2">{metric.value}</Typography>
                    </Grid>
                  );
                return null;
              })}
          </Grid>
        </React.Fragment>
      )}

      <Stack
        spacing={1}
        direction="row"
        style={{ margin: customer?.documents.length < 0 ? "0 0 20px" : "0" }}
      >
        {customer?.documents?.length > 0 &&
          customer?.documents
            ?.filter((doc) => ["Bank statements"].includes(doc.name))
            .map((doc) => <Document document={doc} customer={customer} name="financialStatement" />)}
      </Stack>
      <Grid item sm={6} marginTop={"20px"} marginBottom={"20px"}>
        <Typography variant="subtitle2">Bank statements days</Typography>
        <RadioButtonGroup
          required
          disabled={!isDisable}
          name={"statementsDays"}
          value={bankStatementDays}
          handleValue={(value) => {
            handle.setStatementsDays(value);
          }}
          optionStyle={{
            width: "50%",
            padding: "8px",
            minHeight: "20px",
          }}
          options={[
            { icon: "90", label: "90" },
            { icon: "180", label: "180" },
            { icon: "270", label: "270" },
            { icon: "365", label: "365" },
          ]}
        />
      </Grid>

      <Grid item sm={6}>
        <HtmlTooltip
          tooltipPadding={"20px"}
          title={
            userDetails?.is_invoice_pending ? (
              <React.Fragment>
                <Typography color="inherit" marginBottom="5px">
                  {payInvoiceModalInfo.mainTitle}
                </Typography>
                <Typography color="inherit" fontSize="14px" marginBottom="10px">
                  {payInvoiceModalInfo.SubTitle}
                </Typography>
                {userDetails?.pending_invoices > 0 ? (
                  <Link to={`/billing/transactions`}>
                    <Button variant="outlined" size="small">
                      {payInvoiceModalInfo.btnText}
                    </Button>
                  </Link>
                ) : (
                  <Typography color="inherit" fontSize="12px" marginBottom="10px">
                    Please contact to your organisation
                  </Typography>
                )}
              </React.Fragment>
            ) : userDetails?.is_service_enable ? (
              <React.Fragment>
                <Typography color="inherit" marginBottom="5px">
                  {creditCardModalInfo.mainTitle}
                </Typography>
                <Typography color="inherit" fontSize="14px" marginBottom="10px">
                  {creditCardModalInfo.subTitle}
                </Typography>
                <Link to={`/billing/creditCard`}>
                  <Button variant="outlined" size="small">
                    {creditCardModalInfo.btnText}
                  </Button>
                </Link>
              </React.Fragment>
            ) : null
          }
        >
          <Card>
            <CardActionArea
              disableRipple={isBankStatementDisabled ? true : false}
              onClick={() => {
                if (isBankStatementDisabled) return;
                handle.handleSendBankStatementsLink(
                  customer._id,
                  applicationId,
                  customer?.phoneNumbers.slice(-1)[0],
                  bankStatementDays,
                );
              }}
            >
              <CustomCardSending
                title={"Get bank statements"}
                disabled={isBankStatementDisabled}
                description={`Send a self service link and get
              access to their bank statements via illion Bank Statements.`}
                imagePath={"/static/illion-logo.png"}
                loadingState={bankStatementsLoading}
                financialState={true}
                icon={false}
                lastTitle={"Sending SMS link..."}
              />
            </CardActionArea>
          </Card>
          {(!isCustomerEmail || !isCustomerPhoneNumber) && (
            <Typography variant="caption">
              Sending a bank statements link requires a customer {!isCustomerEmail && 'email, '} {!isCustomerPhoneNumber && 'mobile number'}
            </Typography>
          )}
          {bankStatementsRequest && <Typography variant="caption">{SUCCESS_MESSAGE.bankStatements}</Typography>}
        </HtmlTooltip>
      </Grid>

      {bankStatementLink && handle.getBankStatementLink(bankStatementLink) && (
        <Stack
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            marginTop: "15px",
          }}
        >
          <Typography fontWeight={600}>
            Link :
          </Typography>

          <Typography
            component="a"
            href={handle.getBankStatementLink(bankStatementLink)}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: "underline",
              color: "primary.main",
              cursor: "pointer",
              "&:hover": {
                color: "secondary.main",
              },
            }}
          >
            {handle.getBankStatementLink(bankStatementLink)}
          </Typography>
        </Stack>
      )}
    </React.Fragment >
  );
});

export default CustomerFinancialStatement;
