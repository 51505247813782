import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sheet, Stack, Typography, Button } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { deleteLenderSetting } from "@/store/slices/userSlice";
import type { UserSlice, UserState } from "@/store/slices/types/userSlice";
import { useLenderContext } from "../LendersSettingsContext";

const LendersRemove = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLender, setOpenLendersModal, setSelectedLender, setLendersSnackbar } = useLenderContext();
  const { lender: lenderName } = selectedLender ?? {};
  const dispatch = useDispatch();
  const { _id } = useSelector<UserSlice, UserState>(({ user }) => user);

  // Handle remove lender.
  const handleRemoveLender = useCallback(() => {
    setIsLoading(true);
    // Dispatch the action.
    dispatch(
      deleteLenderSetting({
        user: _id ?? "",
        lenderSettings: [{ _id: selectedLender?._id ?? "" }],
      }),
    )
      // @ts-expect-error: existing dispatch
      .then(({ payload }) => {
        if (payload.data.data) {
          // Close the modal.
          setOpenLendersModal(null);
          setSelectedLender(null);
          // Show snackbar.
          setLendersSnackbar({
            open: true,
            message: `Lender ${selectedLender?.lender} was successfully removed from the lenders list.`,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedLender]);

  return (
    <Sheet>
      <Stack alignItems="center" textAlign="center" rowGap={4}>
        <Typography component="h2" id="modal-title" level="title-md" data-testid="modal-remove-title">
          Remove Lender
        </Typography>
        <Typography id="modal-desc" data-testid="modal-remove-desc">
          Are you sure you want to remove Lender{" "}
          <Typography component="span" textColor="text.primary">
            '{lenderName}'
          </Typography>{" "}
          from the lender's list?
        </Typography>
        <Stack direction="row" columnGap={2} width="100%" justifyContent="flex-end" mt={4}>
          <Button
            variant="secondary"
            fullWidth
            onClick={() => {
              setOpenLendersModal(null);
              setSelectedLender(null);
            }}
          >
            Cancel
          </Button>
          <Button intent="danger" fullWidth onClick={handleRemoveLender} loading={isLoading}>
            Remove
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
};

export { LendersRemove };
