import "./services/sentry";
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import CacheBuster from "react-cache-buster";
import { Typography } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { PersistGate } from "redux-persist/integration/react";
import { PostHogProvider } from "posthog-js/react";
import { store, persistor } from './store'

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import PageLoader from "./components/Application/PageLoader";
import { Error } from "./components/Error";
import { PostHogOptions } from "./services/postHog";
import PackageJson from "../package.json"
import "@fontsource/cabin";
// import { Auth0Provider } from "@auth0/auth0-react";

// ----------------------------------------------------------------------
const isProduction = import.meta.env.NODE_ENV === "production";

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <CacheBuster
        currentVersion={PackageJson?.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        loadingComponent={<PageLoader></PageLoader>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <Provider store={store}>
          <PersistGate loading={<Typography>loading</Typography>} persistor={persistor}>
            <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={PostHogOptions}>
              <ErrorBoundary fallback={<Error />}>
                <App />
              </ErrorBoundary>
            </PostHogProvider>
          </PersistGate>
        </Provider>
      </CacheBuster>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root"),
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
