import React, { useEffect, useState } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { wrapUseRoutes } from "@sentry/react";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// // layouts
// import PreAuthWrapper from "./containers/PreAuthWrapper";
import { userPermissionsSelector, validateToken } from "./store/slices/userSlice";
import AuthWrapper from "./containers/AuthWrapper";
import Application from "./components/Application";
import ApplicationsList from "./components/Application/ApplicationsList";
// import Organisation from "./components/Register/Organisation";
// import Login from "./components/User/Login";
// import Organisation from "./components/Register/Organisation";
// import QuotesList from "./components/User/QuotesList";
// import Register from "./components/Register";
import NotFound from "./pages/NotFound";
import InviteUsers from "./components/Register/InviteUsers";
import ForgotPassword from "./components/User/ForgotPassword";
// import ResetPassword from "./components/User/ResetPassword";
import UserProfile from "./components/User/UserProfile";
import Settings from "./components/Settings";
import OrganisationProfile from "./components/Organisation/OrganisationProfile";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import UserList from "./components/User/UserList";
import Billing from "./components/Billing";
import QuotesList from "./components/Quote/QuoteList";
import Quote from "./components/Quote";
import ContactDetails from "./pages/ContactDetails";
import Calculator from "./components/Calculator";
import CalculatorList from "./components/Calculator/CalculatorList";
import VerifyEmail from "./components/Register/VerifyEmail";
import AcceptInvite from "./components/Register/AcceptInvite";
// import Auth0Login from "./components/User/Auth0Login";
import OrganisationList from "./components/Organisation/OrganisationList";
import OrganisationAdd from "./components/Organisation/OrganisationAdd";
import RolePermission from "./components/Settings/RolePermission";
import { Permission } from "./components/Settings/RolePermission/Permission";

// Unauthenticated pages
import { SignUp } from "./pages/SignUp";
import { SignIn } from "./pages/SignIn";

export default function Router(props) {
  const dispatch = useDispatch();
  let location = useLocation();
  const userPermission = useSelector(userPermissionsSelector);
  const [isAuthenticated, setAuthenticated] = useState(false);

  async function handleValidateToken() {
    await dispatch(validateToken()).then(() => {
      setAuthenticated(true);
    });
  }

  useEffect(async () => {
    // These are locations that we do not require the user to be authenticated.
    // TODO: Upgrade React Router to v6.10.0 to use the loader hook instead of useEffect.
    // https://financeable.atlassian.net/browse/FR-1013
    if (
      location.pathname !== "/contact-details" &&
      location.pathname !== "/privacy" &&
      location.pathname !== "/sign-up" &&
      location.pathname !== "/sign-in"
    ) {
      handleValidateToken();
    }
  }, [location]);

  // Wrap useRoutes with Sentry to enable Sentry to reach the router's context.
  const useSentryRoutes = wrapUseRoutes(useRoutes);

  return useSentryRoutes([
    { path: "/contact-details", element: <ContactDetails /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/sign-in", element: <SignIn /> },
    {
      path: "/",
      element: !isAuthenticated ? <div>Loading</div> : <AuthWrapper {...props} />,
      children: [
        // { path: "register", element: <Register /> },
        // { path: "confirm", element: <ConfirmEmail /> },
        // { path: "/auth0-login", element: <Auth0Login /> },
        // { path: "login", element: <Login /> },
        { path: "verify-email/:key", element: <VerifyEmail /> },
        { path: "accept-invite/:key", element: <AcceptInvite /> },
        { path: "contact-details", element: <ContactDetails /> },
        // { path: "forgotpassword", element: <Password /> },
        // { path: "reset-password/:resetToken", element: <UpdatePassword /> },
        // { path: "/", element: <Navigate to="/login" /> },
        { path: "404", element: <NotFound /> },
        // { path: "privacy", element: <Privacy /> },
        { path: "terms-and-conditions", element: <Terms /> },
        { path: "invite-users", element: <InviteUsers /> },
        { path: "quotes", element: <QuotesList /> },
        { path: "quote/:applicationId", element: <Quote /> },
        { path: "/", element: <Navigate to="/applications" /> },
        { path: "invite/:userId", element: <InviteUsers /> },
        { path: "applications", index: true, element: <ApplicationsList /> },
        { path: "application/:applicationId", element: <Application /> },
        { path: "organisation", element: <OrganisationList /> },
        {
          path: "organisation/new",
          element: userPermission?.org?.create ? <OrganisationAdd /> : <Navigate to="/organisation" />,
        }, // route protected needs
        {
          path: "organisation/:orgId",
          element: userPermission?.org?.edit ? <OrganisationAdd /> : <Navigate to="/organisation" />,
        },
        {
          path: "organisation/rolepermissions/:organisationId",
          element: userPermission?.org?.permission_edit ? <RolePermission /> : <Navigate to="/organisation" />,
        }, // route protected needs
        { path: "users", element: <UserList /> },
        { path: "calculator", element: <CalculatorList /> },
        { path: "calculator/:applicationId", element: <Calculator /> },
        {
          path: "user/rolepermissions/:userId",
          element: userPermission?.user?.permission_edit ? <Permission /> : <Navigate to="/users" />,
        }, // route protected needs
        { path: "settings/:path/*", element: <Settings /> },
        { path: "billing/:path", element: true ? <Billing /> : <Navigate to="/" /> },
        {
          path: "/account",
          children: [
            { path: "profile", element: <UserProfile /> },
            { path: "organisation", element: <OrganisationProfile /> },
          ],
        },
        {
          path: "/password",
          children: [
            { path: "forgot", element: <ForgotPassword /> },
            // { path: "reset/:key", element: <ResetPassword user={user} /> },
          ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
