interface EvaluatedQuoteFees {
  repayments: number;
  brokerage: number;
  brokerageAmount: number;
  financeAmount: number;
  loanPayableAmount: number;
  loanAmount: number;
  effectiveRate: number;
  feesTotal: number;
}

interface QuoteCalculatorField {
  rate: string | number;
  brokerage: string;
  // TODO: type fees according to application.loanDetails.fees.
  fees: any[];
  inArrears: string;
}

interface HandleManualQuoteFieldsParams {
  fieldName: string;
  value: string;
  evaluatedQuoteFees: EvaluatedQuoteFees;
  quoteCalculatorField: QuoteCalculatorField;
}

const getQuoteFieldValues = ({
  fieldName,
  value,
  evaluatedQuoteFees,
  quoteCalculatorField,
}: HandleManualQuoteFieldsParams) => {
  const { brokerageAmount, loanAmount: loanAmountFromQuoteFees } = evaluatedQuoteFees;
  const { rate, brokerage, fees, inArrears } = quoteCalculatorField;
  const loanAmount = +loanAmountFromQuoteFees;

  if (fieldName === "value" || fieldName === "frequency") {
    return {
      fees,
      loanAmount,
    };
  }
  if (fieldName === "rate") {
    return {
      rate: +rate,
      loanAmount,
    };
  }
  if (fieldName === "brokerage") {
    return {
      brokerage: +brokerage,
      brokerageAmount: +brokerageAmount,
      loanAmount,
    };
  }
  if (fieldName === "inArrears") {
    return {
      inArrears,
      loanAmount,
    };
  }
  return { [fieldName]: value };
};

export { getQuoteFieldValues };
