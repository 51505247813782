import { useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Sheet, Stack, Typography, Button, Autocomplete } from "@financeable-com-au/financeable-ui";
import { type FormikHelpers, useFormik } from "formik";
// @ts-expect-error: ignoring as it is existing js file
import { createLenderSetting } from "@/store/slices/userSlice";
import type { UserSlice, UserState } from "@/store/slices/types/userSlice";
import { useLenderContext } from "../LendersSettingsContext";
import { LendersForm } from "../LendersForm";
import { useLenderForm } from "../hooks";

const LendersAdd = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { _id } = useSelector<UserSlice, UserState>(({ user }) => user);
  const dispatch = useDispatch();

  // Set up formik.
  const lenderForm = useLenderForm();
  const { validationSchema, initialValues } = lenderForm ?? {};
  const formik = useFormik({
    initialValues: initialValues ?? {},
    onSubmit: (values: Record<string, string>, _helpers: FormikHelpers<Record<string, string>>) => {
      handleAddLender(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
  });

  // Get lender context.
  const { setSelectedLender, selectedLender, remainingLenders, setOpenLendersModal, setLendersSnackbar } =
    useLenderContext();

  // Create lender options for the select dropdown.
  const lenderOptions = useMemo(
    () =>
      remainingLenders.map(({ lender: lenderName, _id }) => ({
        label: lenderName,
        value: _id,
      })),
    [remainingLenders],
  );

  // Handle the selection of a lender from the dropdown.
  const handleSelectLender = useCallback(
    (_event: React.SyntheticEvent | null, selection: { label: string; value: string } | null) => {
      // If user clears the selection, reset the selected lender.
      if (!selection) {
        setSelectedLender(null);
        return;
      }
      const { value } = selection ?? {};
      // Select the lender from the remaining lenders.
      const selectedLender = remainingLenders.find(({ _id }) => _id === value);
      if (selectedLender) {
        setSelectedLender({
          _id: selectedLender._id,
          lender: selectedLender.lender,
          lenderCredentials: selectedLender.lenderCredentials,
          apiKey: selectedLender.apiKey,
          organisationId: selectedLender.organisationId,
          brokerId: selectedLender.brokerId,
          password: selectedLender.password,
          username: selectedLender.username,
          lenderEmail: selectedLender.lenderEmail,
          user: selectedLender.user,
        });
      }
      // Reset the form.
      formik.resetForm();
    },
    [remainingLenders],
  );

  // Handle add lender.
  const handleAddLender = useCallback(
    (values: Record<string, string>) => {
      setIsLoading(true);
      const { email, ...restValues } = values;
      // Dispatch the action.
      dispatch(
        createLenderSetting({
          ...restValues,
          lenderEmail: email,
          lender: selectedLender?.lender,
          user: selectedLender?.user ?? _id,
        }),
      )
        // @ts-expect-error: existing dispatch
        .then(({ payload }) => {
          if (payload.data) {
            // Reset the form.
            formik.resetForm();
            // Close the modal.
            setOpenLendersModal(null);
            setSelectedLender(null);
            // Show snackbar.
            setLendersSnackbar({
              open: true,
              message: `Lender ${selectedLender?.lender} was successfully added to the lenders list.`,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [selectedLender, _id],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Sheet sx={{ pb: 0 }}>
        <Stack direction="column" rowGap={2}>
          <Typography component="h2" id="modal-title" level="title-md">
            Add Lender
          </Typography>
          <Autocomplete
            placeholder="Search lender"
            options={lenderOptions}
            onChange={(e, selection) =>
              handleSelectLender(e, selection as unknown as { label: string; value: string } | null)
            }
          />
        </Stack>
      </Sheet>
      <Sheet sx={{ maxHeight: "calc(100vh - 500px)", pb: 0, overflowY: "auto" }}>
        {selectedLender ? (
          <LendersForm formik={formik} />
        ) : (
          <Stack direction="column" rowGap={4}>
            <Typography level="body-md">
              This is where you can add your credentials for a lender you want to submit applications to automatically.
            </Typography>
            <Divider />
            <Stack>
              <Typography level="body-sm">1. Select the lender from the drop down above.</Typography>
              <Typography level="body-sm">2. Review what credentials you need and how to get them.</Typography>
              <Typography level="body-sm">3. Input your credentials in the fields shown.</Typography>
              <Typography level="body-sm">4. Hit save and you're ready to start submitting applications!</Typography>
            </Stack>
          </Stack>
        )}
      </Sheet>
      <Sheet>
        <Stack direction="row" columnGap={2} width="100%" justifyContent="flex-end">
          <Button
            variant="secondary"
            fullWidth
            onClick={() => {
              setOpenLendersModal(null);
              setSelectedLender(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" fullWidth type="submit" loading={isLoading}>
            Add Lender
          </Button>
        </Stack>
      </Sheet>
    </form>
  );
};

export { LendersAdd };
