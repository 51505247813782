import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useFeatureFlags } from "@/utils/featureFlags/useFeatureFlags";
import UserDetails from "./profile/UserDetails";
import LegacyLenders from "./legacyLenders/Lender";
import { Lenders } from "./Lenders";
import UserRolesList from "./UserRolesList";
import RolePermission from "./RolePermission";
import Templates from "./Templates";
import TemplateEditor from "./Templates/TemplateEditor";
import BillingWrapper from "../../containers/BillingWrapper";
import { userSelector } from "../../store/slices/userSlice";
import { getTemplates, templatesSelector } from "../../store/slices/templatesSlice";
import { emailTemplatePath, MESSAGE_TYPE, smsTemplatePath, userType } from "../../constants";
import SettingsSidebar from "../../layouts/SettingsSidebar";

// const templateData = [
//   {
//     id: 1,
//     title: "Bank statements",
//     status: "Used",
//     type: "Email",
//     versions: 1,
//     imageUrl: "/path-to-image.png", // Image URL or placeholder
//   },
//   {
//     id: 2,
//     title: "Application Lodged",
//     status: "Unused",
//     type: "Email",
//     versions: 0,
//     imageUrl: "/path-to-image.png", // Image URL or placeholder
//   },
//   {
//     id: 3,
//     title: "Privacy statement",
//     status: "Unused",
//     type: "Email",
//     versions: 0,
//     imageUrl: "/path-to-image.png", // Image URL or placeholder
//   },
// ];

export default function UserProfile() {
  // Feature flags.
  const { lenderCredentialsUplift } = useFeatureFlags();
  const dispatch = useDispatch();

  const templatePathRegex = /^\/settings\/templates\/(?!sms|email)\w+$/;
  const { path } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userState = useSelector(userSelector);

  const { templates } = useSelector(templatesSelector);

  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    if (templatePathRegex.test(location.pathname)) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }

    if (location.pathname === emailTemplatePath || location.pathname === smsTemplatePath) {
      dispatch(
        getTemplates({
          limit: 50,
          cursor: 1,
          messageType: location.pathname?.includes(MESSAGE_TYPE.EMAIL) ? MESSAGE_TYPE.EMAIL : MESSAGE_TYPE.SMS,
        }),
      );
    }
  }, [location.pathname]);

  const handleEditTemplate = (id) => {
    // Handle navigation or editing logic here
    setHideSidebar(true);
    navigate(`/settings/templates/${id}`);
  };

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        spacing={3}
        // style={{ padding: "30px 50px 100px" }}
        // justifyContent="center"
        // alignItems="center"
        direction="row"
      >
        {!hideSidebar && (
          <Grid
            container
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <SettingsSidebar {...{ path }} />
          </Grid>
        )}
        {path === "general" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <UserDetails />
          </Grid>
        )}

        {path === "lenders" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            {lenderCredentialsUplift ? <Lenders /> : <LegacyLenders />}
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "user" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"User"}>
              <UserRolesList />
            </BillingWrapper>
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "rolepermissions" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"Role & Permissions"}>
              <RolePermission />
            </BillingWrapper>
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) &&
          path === "templates" &&
          (location.pathname === emailTemplatePath || location.pathname === smsTemplatePath) && (
            <Grid
              container
              item
              xs={12}
              sm={9}
              md={9}
              lg={8}
              width="100%"
              justifyContent="start"
              alignItems="start"
              direction="column"
              marginLeft={"24px"}
            >
              <BillingWrapper title={(location.pathname === emailTemplatePath ? "Email" : "SMS") + " Templates"}>
                <Templates templates={templates} onEditTemplate={handleEditTemplate} />
              </BillingWrapper>
            </Grid>
          )}
        {[userType.owner, userType.master].includes(userState?.userType) &&
          path === "templates" &&
          templatePathRegex.test(location.pathname) && (
            <Grid
              container
              item
              xs={12}
              sm={9}
              md={12}
              lg={12}
              width="100%"
              justifyContent="start"
              alignItems="start"
              direction="column"
              marginLeft={"24px"}
            >
              <TemplateEditor />
            </Grid>
          )}
      </Grid>
    </>
  );
}
