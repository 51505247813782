import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, StatusExpandCard, StatusExpandContent, Button } from "@financeable-com-au/financeable-ui";

const LenderProductCard = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <StatusExpandCard status="green">
      <Stack flexDirection="column" gap={2} alignItems="flex-start">
        This is the new lender product card
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          variant="inline"
          endDecorator={
            <ExpandMoreIcon
              sx={{
                fontSize: "1rem",
                transition: "transform 0.2s ease",
                ...(isExpanded && { transform: "rotate(180deg)" }),
              }}
            />
          }
        >
          Show Criteria
        </Button>
      </Stack>
      <StatusExpandContent expanded={isExpanded} data-testid="lender-product-card-expandable-content">
        Whereas, I am the content that can be expanded, when expanded is set to true.
      </StatusExpandContent>
    </StatusExpandCard>
  );
};

export { LenderProductCard };
