import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Snackbar, IconButton } from "@financeable-com-au/financeable-ui";
import { useLenderContext } from "../LendersSettingsContext";

const autoHideDuration = 3000;

const LendersSnackbar = () => {
  const { lendersSnackbar, setLendersSnackbar } = useLenderContext();
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      open={lendersSnackbar.open}
      intent="success"
      startDecorator={<InfoOutlinedIcon sx={{ fontSize: "1rem" }} />}
      onClose={() => setLendersSnackbar({ open: false, message: "" })}
      endDecorator={
        <IconButton
          data-testid="close-button"
          variant="inline"
          onClick={() => setLendersSnackbar({ open: false, message: "" })}
          intent="success"
        >
          <CloseIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      }
    >
      {lendersSnackbar.message}
    </Snackbar>
  );
};

export { LendersSnackbar };
