import { createContext, useContext } from "react";
import { LenderSettings } from "../../../../store/slices/types/userSlice";

interface LenderCredentials {
  attribute: string;
  label: string;
}

interface FullLendersList extends LenderSettings {
  lenderCredentials: LenderCredentials[];
  logo: string;
}

interface LenderSettingsWithCredentials {
  _id: LenderSettings["_id"];
  lender: LenderSettings["lender"];
  lenderCredentials: LenderCredentials[];
  apiKey: LenderSettings["apiKey"];
  organisationId: LenderSettings["organisationId"];
  brokerId: LenderSettings["brokerId"];
  password: LenderSettings["password"];
  username: LenderSettings["username"];
  lenderEmail: LenderSettings["lenderEmail"];
  user: LenderSettings["user"];
}

type LendersModalType = "add" | "edit" | "remove" | null;

type SelectedLenderType = LenderSettingsWithCredentials | null;

export interface LendersSnackbarType {
  open: boolean;
  message: string;
}

interface LendersSettingsContextType {
  openLendersModal: LendersModalType;
  selectedLender: SelectedLenderType;
  addedLenders: FullLendersList[];
  remainingLenders: FullLendersList[];
  lendersSnackbar: LendersSnackbarType;
  setOpenLendersModal: (open: LendersModalType) => void;
  setSelectedLender: (lender: SelectedLenderType) => void;
  setLendersSnackbar: (snackbar: LendersSnackbarType) => void;
}

const LendersSettingsContext = createContext<LendersSettingsContextType>({
  openLendersModal: null,
  selectedLender: null,
  addedLenders: [],
  remainingLenders: [],
  lendersSnackbar: {
    open: false,
    message: "",
  },
  setOpenLendersModal: () => {},
  setSelectedLender: () => {},
  setLendersSnackbar: () => {},
});

const useLenderContext = () => {
  const context = useContext(LendersSettingsContext);
  if (!context) {
    throw new Error("useLenderContext must be used within LendersSettingsContext");
  }
  return context;
};

export { LendersSettingsContext, useLenderContext };
export type {
  LendersSettingsContextType,
  LendersModalType,
  SelectedLenderType,
  LenderSettingsWithCredentials,
  LenderCredentials,
  FullLendersList,
};
