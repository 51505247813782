import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import type { FormikProps } from "formik";
import {
  Stack,
  Typography,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  Tooltip,
  Divider,
  FormHelperText,
} from "@financeable-com-au/financeable-ui";
import { useLenderForm } from "../hooks";

interface LenderFormProps {
  formik: FormikProps<Record<string, string>>;
}

const LendersForm = ({ formik }: LenderFormProps) => {
  const lenderForm = useLenderForm();

  if (lenderForm) {
    const { title, description, fields } = lenderForm;
    return (
      <Stack direction="column" rowGap={4}>
        <Stack direction="column" rowGap={4}>
          <Stack direction="column" rowGap={2}>
            {title && (
              <Typography component="h3" level="title-md">
                {title}
              </Typography>
            )}
            {description && (
              <Typography component="p" level="body-sm">
                {description}
              </Typography>
            )}
            <Divider />
          </Stack>
          <Stack rowGap={2}>
            {fields.map(({ attribute, label, type, tooltip, value }) => (
              <FormControl key={attribute} error={!!formik.errors[attribute]}>
                <FormLabel>{label}</FormLabel>
                <Stack direction="row" columnGap={2} alignItems="center">
                  <Input
                    placeholder={`Please enter your ${label}`}
                    fullWidth={true}
                    type={type}
                    name={attribute}
                    value={formik.values[attribute] ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <IconButton aria-label="Tooltip" isRounded={true} size="sm">
                        <QuestionMarkIcon sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
                {!!formik.errors[attribute] && (
                  <FormHelperText>
                    <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                    {formik.errors[attribute]}
                  </FormHelperText>
                )}
              </FormControl>
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return null;
};

export { LendersForm };
